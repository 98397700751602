import { TEXT_PROPS } from './default-props';

// Mood Board Color
export const BG_PRIMARY = '#FFF7ED';
export const BG_SECONDARY = '#CBAD9F';
export const BG_ALTERNATIVE = '#CD9F89';
export const BG_OPACITY = '#D6C5BD';
export const BG_LAYER = 'rgb(255 247 237 / 69%)';
export const BG_LAYER_BORDER = '#A48761';

// GRADIENT COLORS
export const BUTTON_GRADIENT = `linear-gradient(to left, ${BG_ALTERNATIVE}, ${BG_OPACITY} 140%)`;

// Text Color
export const TEXT_PRIMARY = '#735C3D'; //brown dark
export const TEXT_SECONDARY = '#7E4B5E'; // Title color section
export const TEXT_ALTERNATIVE = '#FFF7ED'; // Alternative Color purple

// Music & Navigation Color
export const NAVIGATION_COLOR = '#af7c63'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';

// Default Button Color
export const BUTTON_PROPS = {
  _active: { opacity: '.8' },
  _hover: { opacity: '.8' },
  _focus: { opacity: '.8' },
};

export const BUTTON_PADDING_PROPS = {
  ...BUTTON_PROPS,
  ...TEXT_PROPS.body,
  variant: 'solid',
  padding: '18px 24px',
  borderRadius: '40px',
  background: BUTTON_GRADIENT,
  color: TEXT_ALTERNATIVE,
  size: 'sm',
  boxShadow: 'sm',
};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'normal',
};
