import { extendTheme } from '@chakra-ui/react';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  DRAWER_COLOR,
  BG_OPACITY,
  NAVIGATION_COLOR,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,

    // for generator purpose
    jost: `'Jost', sans-serif;`,
    delafield: `'Mrs Saint Delafield', cursive;`,
  },
  fontSizes: {
    heading: '46px',
    subHeading: '46px',
    body: '16px',
  },
  fontWeights: {
    heading: 500,
    subHeading: 400,
    body: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgOpacity: BG_OPACITY,
    bgAlternative: BG_ALTERNATIVE,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    navColorText: NAVIGATION_COLOR,
    drawerColor: DRAWER_COLOR,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
        fontFamily: FONT_FAMILY.heading,
      },
    },
  },
  breakpoints: {
    base: '0px',
    sm: '320px', // galaxy s9+
    '1sm': '375px', //iphone SE
    '2sm': '414px', //iphone XR
    '3sm': '500px', // > tablet
    md: '768px',
    lg: '960px',
    xl: '1200px',
  },
};

export default extendTheme(theme);
