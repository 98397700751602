/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `"Great Vibes", cursive`,
  subHeading: `"Great Vibes", cursive`,
  body: `"Cormorant Upright", serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    // lineHeight: '1.6em',
    textAlign: 'center',
    color: 'secondaryColorText',
    textTransform: 'capitalize',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    lineHeight: '1.2',
    color: 'mainColorText',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    color: 'mainColorText',
    textAlign: 'center',
  },
};
